import * as React from "react"
import { ImgDestra, ImgSinistra, ImgCentro, ImgLogo, Img, Citazione, IndiceLuoghi, PaginaTesto, ChiSono, ChiSiamo, A, FbPage } from "../../../components/componentiPagine"

export default function Render() {
	return <PaginaTesto lang="it" name="tangeri1834">
<h1>Il secondo esilio</h1>
<p>Dal nascondiglio di Pontremoli, Antonio si dirige verso la Campania, e nell’aprile-maggio del 1834 è a Sorrento, dove prende alloggio presso una pensione.</p>
<ImgCentro nome="gibilterra.PNG" alt="Gibilterra"/>
<p>Dopo qualche tempo trova impiego a Napoli come precettore, presso la famiglia del console Giacomo De Martino, che segue poi fino a Tangeri (Marocco), dove il console assume il proprio ufficio e, per un breve periodo intermedio, a Malta.</p>
<ImgCentro nome="tangeri.PNG" alt="Tangeri" didascalia="Tangeri"/>
<ImgDestra nome="nave.PNG" alt="Nave per America"/>
<p>In Marocco Antonio conduce una vita agiata fatta di feste, ricevimenti e flirt con le dame appartenenti alle famiglie dei dignitari europei.<br />
Tuttavia sente che quel tipo di vita non fa per lui e così, sollecitato dal ministro degli Stati Uniti a Tangeri, John Madison Leib, il quale gli parla delle magnifiche possibilità che il suo Paese può offrire agli uomini di cultura, decide di raggiungere Gibilterra (situata sulla costa opposta a Tangeri) e di imbarcarsi per l’America.</p>
<p>Leib addirittura prospetterebbe ad Antonio la possibilità di ottenere una cattedra di letteratura italiana presso l’Università di Harvard. Tra le lettere di raccomandazione che Leib ha scritto per Antonio, una è indirizzata a Edward Everett, futuro governatore del Massachusetts e futuro rettore dell’Università di Harvard, e un’altra proprio all’allora rettore della prestigiosa università (ed ex sindaco di Boston) Josiah Quincy III.</p>
<ImgCentro nome="harvard.PNG" alt="Harvard" didascalia="Università di Harvard"/>
<h2>Link per approfondire:</h2>
<p><A href="https://www.treccani.it/enciclopedia/edward-everett/">Edward Everett</A></p>
<p><A href="https://www.treccani.it/enciclopedia/giacomo-de-martino_res-4d80cac4-87ec-11dc-8e9d-0016357eee51_%28Dizionario-Biografico%29/">Giacomo De Martino</A></p>
<p><A href="https://it.wikipedia.org/wiki/Universit%C3%A0_di_Harvard">Harvard</A></p>
<p><A href="https://en.wikipedia.org/wiki/Josiah_Quincy_III">Josiah Quincy III</A></p>
<p><A href="https://www.treccani.it/enciclopedia/tangeri_%28Enciclopedia-Italiana%29/">Tangeri</A></p>

	</PaginaTesto>
}